/*
 *
 * AccountInfoPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const submitChangePassword = (data: any) => action(ActionTypes.CHANGE_PASSWORD, data);
export const changePassword = createAsyncAction(
  ActionTypes.CHANGE_PASSWORD_REQUEST,
  ActionTypes.CHANGE_PASSWORD_SUCCESS,
  ActionTypes.CHANGE_PASSWORD_FAILURE,
)<void, {}, Error>();
export const submitUpdateProfile = (data: any) => action(ActionTypes.UPDATE_PROFILE, data);
export const updateProfile = createAsyncAction(
  ActionTypes.UPDATE_PROFILE_REQUEST,
  ActionTypes.UPDATE_PROFILE_SUCCESS,
  ActionTypes.UPDATE_PROFILE_FAILURE,
)<void, {}, Error>();
