/*
 *
 * AccountInfoPage constants
 *
 */

enum ActionTypes {
  CHANGE_PASSWORD = 'app/AccountInfoPage/CHANGE_PASSWORD',
  CHANGE_PASSWORD_REQUEST = 'app/AccountInfoPage/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'app/AccountInfoPage/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'app/AccountInfoPage/CHANGE_PASSWORD_FAILURE',
  UPDATE_PROFILE = 'app/AccountInfoPage/UPDATE_PROFILE',
  UPDATE_PROFILE_REQUEST = 'app/AccountInfoPage/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = 'app/AccountInfoPage/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = 'app/AccountInfoPage/UPDATE_PROFILE_FAILURE',
}

export default ActionTypes;
