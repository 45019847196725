/*
 *
 * AccountInfoPage reducer
 *
 */

import { combineReducers } from 'redux';

// import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
};

export default combineReducers<ContainerState, ContainerActions>({
  default: (state = initialState, action) => {
    return state;
  },
});
