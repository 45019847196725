/**
 *
 * AccountInfoPage
 *
 */

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import { connect } from 'react-redux';
// import { FormattedMessage } from "react-intl";
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';

import PageHeader from 'components/PageHeader/PageHeader';
import Account from 'components/Account/Account';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import selectAccountInfoPage from './selectors';
import reducer from './reducer';
import saga from './saga';

import { RootState } from './types';
import { selectUser } from 'containers/MainLayout/selectors';
import { submitChangePassword, submitUpdateProfile } from './actions';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface OwnProps {
  user: any;
}

interface StateProps {}

interface DispatchProps {
  onChangePassword: (data: any) => void;
  onUpdateProfile: (data: any) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class AccountInfoPage extends React.PureComponent<Props> {
  public onUpdateLang = (lang: string) => {
    this.props.onUpdateProfile({
      lang: lang,
    });
  };

  public render() {
    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        <PageHeader>
          <FormattedMessage {...messages.header} />
        </PageHeader>
        <Account
          user={this.props.user}
          onChangePassword={this.props.onChangePassword}
          onUpdateProfile={this.props.onUpdateProfile}
          onUpdateLang={this.onUpdateLang}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  accountinfopage: selectAccountInfoPage(),
  user: selectUser(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onChangePassword: (data: any) => dispatch(submitChangePassword(data)),
    onUpdateProfile: (data: any) => dispatch(submitUpdateProfile(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<OwnProps>({
  key: 'accountInfoPage',
  reducer: reducer,
});

const withSaga = injectSaga<OwnProps>({ key: 'accountInfoPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(AccountInfoPage);
