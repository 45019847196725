import React, { useState } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import Button from '../Button/Button';
import { Avatar } from '../Company/Avatar';
import Radio from '../Radio/Radio';
import Modal from '../Modal/Modal';
import Edit from './Edit.svg';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/AccountInfoPage/messages';
import utilsMessages from 'utils/messages';
import ChangePasswordForm from './ChangePasswordForm';
import EditProfileForm from './EditProfileForm';
import { Box } from '../Box/Box';
import { Language } from 'types/schema';

const Side = styled(Box)``;

const Body = styled.div``;

const InfoContainer = styled.div`
  margin-bottom: 6pt;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4pt;
`;

const Label = styled.span`
  color: rgba(0, 0, 0, 0.54);
  flex: 1;
  text-transform: capitalize;
`;

const Span = styled.span`
  flex: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Value = (props) => {
  return <Span title={props.title}>{props.children}</Span>;
};

const Heading = styled.h4`
  margin-bottom: 6pt;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
`;

const alternativeImage = (name) => `https://ui-avatars.com/api/?name=${name}&size=512`;

export default ({
  user,
  onChangePassword,
  onUpdateProfile,
  onUpdateLang,
}: {
  user: any;
  onChangePassword: (data: any) => void;
  onUpdateProfile: (data: any) => void;
  onUpdateLang: (data: any) => void;
}) => {
  const [isOpenEditModal, onOpenEditModal] = useState(false);
  const [avatarUrl, onSetAvatarUrl] = useState(user.imageUrl || '');
  const closeEditProfileModal = () => onOpenEditModal(false);

  const onChangeLang = (lang: Language) => () => onUpdateLang(lang);

  const onSubmit = (data: any) => {
    data.imageUrl = avatarUrl;
    data.lang = user.lang;
    onUpdateProfile(data);
    onOpenEditModal(false);
  };

  return (
    <Row gutter={8}>
      <Col lg={7} sm={24} xs={24}>
        <Side>
          <Avatar src={user.imageUrl || alternativeImage(user.name)} disabled={true} />
          <InfoContainer>
            <Heading>
              <FormattedMessage {...messages.personalInfo} />
            </Heading>
            <Info>
              <Label>
                <FormattedMessage {...utilsMessages.name} />:
              </Label>
              <Value>{user.name}</Value>
            </Info>
            {/* <Info>
              <Label>Role:</Label>
              <Value>Administrator</Value>
            </Info> */}
          </InfoContainer>
          <InfoContainer>
            <Heading>
              <FormattedMessage {...messages.contactInfo} />
            </Heading>
            <Info>
              <Label>
                <FormattedMessage {...utilsMessages.phone} />:
              </Label>
              <Value>{user.telephone}</Value>
            </Info>
            <Info>
              <Label>
                <FormattedMessage {...utilsMessages.email} />:
              </Label>
              <Value title={user.email}>{user.email}</Value>
            </Info>
          </InfoContainer>
          <Button size="large" block onClick={() => onOpenEditModal(true)}>
            <FormattedMessage {...messages.editProfile} />
          </Button>
        </Side>
      </Col>
      <Col lg={17} sm={24} xs={24}>
        <Body>
          <Box>
            <Heading>
              <FormattedMessage {...messages.changePassword} />
            </Heading>
            <ChangePasswordForm onSubmit={onChangePassword} />
          </Box>
          <Box>
            <InfoContainer>
              <Heading>
                <FormattedMessage {...messages.emailSetting} />
              </Heading>
              <Info onClick={onChangeLang(Language.En)}>
                <Radio checked={user.lang === 'en'}>
                  <FormattedMessage {...messages.receiveEmailInEnglish} />
                </Radio>
              </Info>
              <Info onClick={onChangeLang(Language.Vi)}>
                <Radio checked={user.lang === 'vi'}>
                  <FormattedMessage {...messages.receiveEmailInVietnamese} />
                </Radio>
              </Info>
            </InfoContainer>
          </Box>
        </Body>
      </Col>
      <FormattedMessage {...messages.editAccount}>
        {(editAccount: string) => (
          <Modal size="large" headerLabel={editAccount} headerIcon={Edit} isOpen={isOpenEditModal}>
            <Row gutter={8}>
              <Col lg={8} xs={24}>
                <Heading>
                  <FormattedMessage {...messages.profilePhoto} />
                </Heading>
                <Avatar src={avatarUrl} onUploadImageSuccess={onSetAvatarUrl} />
              </Col>
              <Col lg={16} xs={24}>
                <EditProfileForm user={user} onCancel={closeEditProfileModal} onSubmit={onSubmit} />
              </Col>
            </Row>
          </Modal>
        )}
      </FormattedMessage>
    </Row>
  );
};
