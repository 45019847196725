import { Radio as ARadio } from 'antd';
import styled from 'styled-components';

export default styled(ARadio)<any>`
  ${({ size, checked }) => {
    let style = ``;
    if (size === 'small') {
      style += `
        font-size: 14px;
        .ant-radio-inner {
          width: 12px;
          height: 12px;
          :after {
            width: 6px;
            height: 6px;
            left: 2px;
            top: 2px;
          }
        }
      `;
    }
    if (checked) {
      style += `
        color: rgba(0, 0, 0, 0.87);
      `;
    } else {
      style += `
        color: rgba(0, 0, 0, 0.54);
      `;
    }
    return style;
  }};
`;
