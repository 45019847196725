import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { Input, Form as AForm } from 'antd';
import Button from '../Button/Button';
import messages from 'containers/AccountInfoPage/messages';
import utilsMessages from 'utils/messages';
import translations from 'translations';

const Form = styled(AForm)`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  .ant-form-item {
    flex: 1 1 0%;
    margin-bottom: 0px;
    .ant-form-item-label {
      white-space: nowrap;
    }
  }
  button[type='submit'] {
    margin-top: 22px;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    button[type='submit'] {
      margin-top: 0px;
    }
  }
`;

const Password = styled(Input.Password)<any>``;

const ChangePasswordForm: FC<{ onSubmit: (data: any) => void }> = ({ onSubmit }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit({
      password: values.password,
      currentPassword: values.currentPassword,
    });
    form.resetFields();
  };

  const checkConfirmPassword = () => () => {
    const password = form.getFieldValue('password');
    const confirmPassword = form.getFieldValue('confirmPassword');
    if (!confirmPassword || !password) {
      return Promise.resolve();
    }
    if (confirmPassword && password !== confirmPassword) {
      return Promise.reject(translations(messages.confirmPasswordNotCorrect));
    }
    return Promise.resolve();
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => handleSubmit(values)}
      requiredMark={false}
      onFinishFailed={(error) => Sentry.captureException(error, { level: Sentry.Severity.Warning })}
    >
      <FormattedMessage {...messages.currentPassword}>
        {(currentPassword: string) => (
          <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: currentPassword }}>
            {(fieldRequired) => (
              <Form.Item
                name="currentPassword"
                label={currentPassword}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: fieldRequired,
                  },
                ]}
              >
                <Password size="large" />
              </Form.Item>
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
      <FormattedMessage {...messages.newPassword}>
        {(newPassword: string) => (
          <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: newPassword }}>
            {(fieldRequired) => (
              <Form.Item
                name="password"
                label={newPassword}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: fieldRequired,
                  },
                  {
                    validator: checkConfirmPassword(),
                  },
                ]}
              >
                <Password size="large" />
              </Form.Item>
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
      <FormattedMessage {...messages.confirmPassword}>
        {(confirmPassword: string) => (
          <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: confirmPassword }}>
            {(fieldRequired) => (
              <Form.Item
                name="confirmPassword"
                label={confirmPassword}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: fieldRequired,
                  },
                  {
                    validator: checkConfirmPassword(),
                  },
                ]}
              >
                <Password size="large" />
              </Form.Item>
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
      <Button size="large" htmlType="submit">
        <FormattedMessage {...utilsMessages.change} />
      </Button>
    </Form>
  );
};

export default ChangePasswordForm;
