import { message } from 'antd';
import { take, call, put, fork } from 'redux-saga/effects';
import { user as userActions } from 'containers/MainLayout/actions';
import { changePassword as changePasswordActions, updateProfile as updateProfileActions } from './actions';
import ActionTypes from './constants';
import { updatePassword, updateProfile, updateLang } from 'utils/apollo';

function* changePasswordFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CHANGE_PASSWORD);
    yield put(changePasswordActions.request());
    const response = yield call(updatePassword, {
      input: payload,
    });

    if (!response.errors) {
      yield put(changePasswordActions.success(response));
      message.success('Your password has been changed successfully!');
    } else {
      yield put(changePasswordActions.failure(response.errors));
      message.error(`Changing password failed. ${response.errors[0].message}`);
    }
  }
}

function* updateProfileFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.UPDATE_PROFILE);
    yield put(updateProfileActions.request());
    const response = yield call(updateProfile, {
      input: payload,
    });
    yield call(updateLang, payload);
    if (!response.errors) {
      yield put(updateProfileActions.success({ ...response, ...payload }));
      yield put(userActions.success({ ...response, ...payload }));
    } else {
      yield put(updateProfileActions.failure(response.errors));
    }
  }
}

// Individual exports for testing
export default function* accountInfoPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(changePasswordFlow);
  yield fork(updateProfileFlow);
}
