/*
 * AccountInfoPage Messages
 *
 * This contains all the text for the AccountInfoPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AccountInfoPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AccountInfoPage container!',
  },
  personalInfo: {
    id: `${scope}.personalInfo`,
    defaultMessage: 'Thông tin cá nhân',
  },
  currentPassword: {
    id: `${scope}.currentPassword`,
    defaultMessage: 'Mật khẩu hiện tại',
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'Mật khẩu mới',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Xác nhận mật khẩu mới',
  },
  changePassword: {
    id: `${scope}.changePassword`,
    defaultMessage: 'Thay đổi mật khẩu',
  },
  editProfile: {
    id: `${scope}.editProfile`,
    defaultMessage: 'Cập nhật thông tin',
  },
  emailSetting: {
    id: `${scope}.emailSetting`,
    defaultMessage: 'Cài đặt email',
  },
  receiveEmailInEnglish: {
    id: `${scope}.receiveEmailInEnglish`,
    defaultMessage: 'Nhận email bằng Tiếng Anh',
  },
  receiveEmailInVietnamese: {
    id: `${scope}.receiveEmailInVietnamese`,
    defaultMessage: 'Nhận email bằng Tiếng Việt',
  },
  contactInfo: {
    id: `${scope}.contactInfo`,
    defaultMessage: 'Thông tin liên lạc',
  },
  profilePhoto: {
    id: `${scope}.profilePhoto`,
    defaultMessage: 'Ảnh đại diện',
  },
  editAccount: {
    id: `${scope}.editAccount`,
    defaultMessage: 'Cập nhật tài khoản',
  },
  fullName: {
    id: `${scope}.fullName`,
    defaultMessage: 'Họ và Tên',
  },
  confirmPasswordNotCorrect: {
    id: `${scope}.confirmPasswordNotCorrect`,
    defaultMessage: 'Xác nhận mật khẩu không đúng!',
  },
});
