import React, { FC } from 'react';
import styled from 'styled-components';
import { Row, Col, Input, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/AccountInfoPage/messages';
import utilsMessages from 'utils/messages';
import Button from '../Button/Button';
import { User } from 'types/schema';

const InfoContainer = styled.div`
  margin-bottom: 6pt;
`;

const Heading = styled.h4`
  margin-bottom: 6pt;
  text-transform: uppercase;
`;
interface IProps {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  user: User;
}
const EditProfileForm: FC<IProps> = ({ onSubmit, onCancel, user }) => {
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    onSubmit(values);
    onCancel();
  };

  const handleCancel = (event: Event) => {
    event.preventDefault();
    form.resetFields();
    onCancel();
  };

  return (
    <Form form={form} layout="vertical" requiredMark={false} onFinish={handleSubmit} size="large">
      <InfoContainer>
        <Heading>
          <FormattedMessage {...messages.personalInfo} />
        </Heading>
        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <FormattedMessage {...messages.fullName}>
              {(fullName) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: fullName }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name="name"
                      initialValue={user.name}
                      label={fullName}
                      colon={false}
                      rules={[{ required: true, message: fieldRequired }]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </InfoContainer>
      <InfoContainer>
        <Heading>
          <FormattedMessage {...messages.contactInfo} />
        </Heading>

        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <FormattedMessage {...utilsMessages.phone}>
              {(phone) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: phone }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name="telephone"
                      initialValue={user.telephone}
                      label={phone}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: fieldRequired,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </InfoContainer>
      <Row>
        <Col offset={8} span={8}>
          <Button block type="secondary" ghost onClick={handleCancel}>
            <FormattedMessage {...utilsMessages.cancel} />
          </Button>
        </Col>
        <Col span={8}>
          <Button block type="primary" htmlType="submit">
            <FormattedMessage {...utilsMessages.save} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditProfileForm;
